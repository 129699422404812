import React, {useState} from "react";
import {DialogContent, IconButton, Menu} from "@mui/material";
import {MdOutlineAttachFile, MdOutlineFileDownload} from "react-icons/md";
import {VPLTooltip} from "../VPLTooltip";
import FileUpload from "./FileUpload";
import {ContentCreationClient} from "../../services/ContentCreationClient";
import {VPLDialogActions} from "../VPLDialogActions";
import {ProgressBar} from "../ProgressBar";
import {Error} from "../Error";

export const PartAttachment = ({requestId, part}) => {
    const [selectedFile, setSelectedFile] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const open = Boolean(anchorEl);
    const anchorOrig = {
        vertical: "top",
        horizontal: "left"
    };

    function handleClose() {
        setAnchorEl(null);
    }

    function setFile(files) {
        setSelectedFile(files.length > 0 ? files[0] : null);
    }

    function closeErrorMessage() {
        setError(false);
        setErrorMessage(null);
    }

    function handleSubmission() {
        if (selectedFile) {
            const ccClient = new ContentCreationClient();
            setLoading(true);
            ccClient.addPartAttachment(requestId, part.id, selectedFile,
                () => {
                    setLoading(false);
                    part.attachment = selectedFile.name;
                    handleClose();
                },
                (newError) => {
                    setLoading(false);
                    setError(true);
                    setErrorMessage(newError);
                });
        }
    }

    function handleDownload() {
        const ccClient = new ContentCreationClient();
        ccClient.downloadPartAttachment(requestId, part.id, part.attachment);
    }

    return (
        <React.Fragment>
            <VPLTooltip
                placement="bottom-start"
                title={part.attachment ? "Download attachment" : "Attach datasheets"}
            >
                <IconButton
                    style={{padding: 0, margin: 5}}
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget)
                    }}
                    size="large">
                    {!part.attachment
                        ? <MdOutlineAttachFile style={{color: "lightblue"}}/>
                        : <MdOutlineFileDownload style={{color: "lightblue"}}/>
                    }
                </IconButton>
            </VPLTooltip>
            <Menu
                id="simple-menu"
                keepMounted
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={anchorOrig}
                transformOrigin={anchorOrig}
                slotProps={{
                    paper: {
                        style: {
                            width: 800
                        }
                    }
                }}
            >
                {loading && <ProgressBar/>}
                <Error
                    error={error}
                    errorMessage={errorMessage}
                    closeErrorMessage={closeErrorMessage}
                />
                <DialogContent>
                    {part.attachment &&
                        <VPLTooltip
                            placement="bottom-start"
                            title={part.attachment}
                        >
                            <IconButton
                                style={{fontSize: 16, textTransform: 'none', padding: 0, margin: 5}}
                                onClick={handleDownload}
                                size="large">
                                Download attachment
                                <MdOutlineFileDownload style={{color: "black"}}/>
                            </IconButton>
                        </VPLTooltip>
                    }
                    {part.attachment && <div>Note: uploading a new attachment will override the previous one</div>}
                    <FileUpload multiple={false} updateFilesCb={setFile} callUpdateFilesCb={setFile}
                                dragLabel={"Drag and drop your file"}/>
                </DialogContent>
                <VPLDialogActions handleClose={handleClose} handleSubmit={handleSubmission}/>

            </Menu>
        </React.Fragment>
    );
}