import React, {useEffect, useRef, useState} from "react";
import Two from "two.js";
import {ContentCreationClient} from "../../services/ContentCreationClient";
import styled from 'styled-components';
import {PackageTabs} from "./PackageTabs";
import Grid from "@mui/material/Grid2";

const Draw = styled.div`
  padding: 1em;
  margin: 1em;
  background: white;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
`;

const BODY_COLOR = 'rgb(87,85,80)';
const LEAD_COLOR = '#DFEBEF';
const HOVERED_SHAPE_COLOR = 'white';
const TOOLTIP_COLOR = 'cornsilk';

export default function ComponentViewer({mpn, mnf}) {
    let two = new Two({fullscreen: false, autostart: true});
    let twoRef = useRef();
    const client = new ContentCreationClient();
    const [pkg, setPkg] = useState({});

    function readGroupsMetadata(groups, units) {
        const leadGroups = {};
        if (groups) {
            for (let group of groups.childNodes) {
                leadGroups[group.getAttribute("id")] = {
                    form: group.getAttribute("form"),
                    mount: group.getAttribute("mount"),
                    type: group.getAttribute("type"),
                    size: group.getAttribute("width") + "x" + group.getAttribute("length") + units,
                    nx: group.getAttribute("nx"),
                    ny: group.getAttribute("ny"),
                    dx: group.getAttribute("dx"),
                    dy: group.getAttribute("dy")
                };
            }
        }
        return leadGroups;
    }

    function readLeadsMetadata(leads) {
        const leadIds = {};
        if (leads) {
            for (let lead of leads.childNodes) {
                leadIds[lead.getAttribute("id")] = {
                    x: lead.getAttribute("x"),
                    y: lead.getAttribute("y")
                };
            }
        }
        return leadIds;
    }

    function readBodyMetadata(body) {
        return {
            type: body.getAttribute("type"),
            mount: body.getAttribute("mount"),
            direction: body.getAttribute("direction"),
            size: body.getAttribute("size")
        };
    }

    function getSVG(serverSvg) {
        let parser = new DOMParser();
        let svgXml = parser.parseFromString(serverSvg, "text/xml").documentElement;
        let twoSvg = two.interpret(svgXml);
        two.update();
        let metadata = svgXml.childNodes[0];
        let body = svgXml.childNodes[0].childNodes[0];
        let groups = svgXml.childNodes[0].childNodes[1];
        let leads = svgXml.childNodes[0].childNodes[2];
        let units = metadata.getAttribute("units");
        return {
            svg: twoSvg,
            pkg: {
                part: {mpn: mpn, manufacturer: mnf, description: ""},
                body: readBodyMetadata(body),
                groups: readGroupsMetadata(groups, units),
                leads: readLeadsMetadata(leads),
                units: units
            },
            ctm: svgXml.getScreenCTM()
        };
    }

    function prepareSVG(obj) {
        const svg = obj.svg;
        let i = 0;
        while (i < svg.children.length - 1) {
            const shape = svg.children[i];
            if (shape.id === 'lbody') {
                shape.fill = BODY_COLOR;
                setBodyMouseClickEvent(shape, obj.pkg.body);
            } else {
                shape.fill = LEAD_COLOR;
                setLeadMouseClickEvent(shape, obj.pkg.leads[shape.id], obj.pkg.groups[shape.id.substring(0, shape.id.indexOf("."))], obj.pkg.units);
            }
            i++;
            setMouseOutEvent(shape, shape.fill);
        }
    }

    function setBodyMouseClickEvent(shape, body) {
        shape._renderer.elem.addEventListener('click', function (e) {
            shape.fill = HOVERED_SHAPE_COLOR;
            showBodyTooltip(e, shape, body);
        }, false);
    }

    function setLeadMouseClickEvent(shape, lead, group, units) {
        shape._renderer.elem.addEventListener('click', function (e) {
            shape.fill = HOVERED_SHAPE_COLOR;
            showLeadTooltip(e, lead, group, units);
        }, false);
    }

    function showLeadTooltip(evt, lead, group, units) {
        let tooltip = document.getElementById("tooltip");
        tooltip.innerHTML =
            "<div><header style='font-weight: bold'>Lead information</header>" +
            "<p>Location: (" + lead.x + "," + lead.y + ")" + units + "</p><p>Size: " + group.size + "</p><p>Lead form: " + group.form + "</p><p>Lead type: " + group.type + "</p><p>Mount type: " + group.mount + "</p></div>";
        tooltip.style.display = "block";
        tooltip.style.left = evt.pageX + 'px';
        tooltip.style.top = evt.pageY + 'px';
    }

    function showBodyTooltip(evt, shape, body) {
        let tooltip = document.getElementById("tooltip");
        tooltip.innerHTML =
            "<div><header style='font-weight: bold'>Body information</header></p><p>Size: " + body.size + "</p><p>Type: " + body.type + "</p></div>";
        tooltip.style.display = "block";
        tooltip.style.left = evt.pageX + 'px';
        tooltip.style.top = evt.pageY + 'px';
    }

    function setMouseOutEvent(shape, originalColor) {
        shape._renderer.elem.addEventListener('mouseout', function () {
            shape.fill = originalColor;
            hideTooltip();
        }, false);
    }

    function hideTooltip() {
        const tooltip = document.getElementById("tooltip");
        tooltip.style.display = "none";
    }

    useEffect(() => {
        if (mpn && mnf) {
            client.getVplComponent(mpn, mnf, (xml) => {
                const obj = getSVG(xml);
                prepareSVG(obj);
                two.renderer.setSize(600, 600);
                two.appendTo(twoRef.current);
                two.update();
                setPkg(obj.pkg);
            }, (error) => {
                console.log(error)
            });
        }
    }, [mpn, mnf])

    return (
        <div style={{background: 'white', padding: 10, margin: 10}}>
            <div id="tooltip" style={{
                position: 'fixed', display: 'none', background: TOOLTIP_COLOR,
                borderRadius: '5px', border: '1px solid black', padding: 2
            }}/>
            <Grid direction={"row"} container alignItems={"stretch"} alignContent={"stretch"}
                  justifyContent={"flex-start"}>
                <Grid xs={"5"}>
                    <Draw ref={twoRef}/>
                </Grid>
                <Grid xs={"7"}><PackageTabs pkg={pkg}/></Grid>
            </Grid>
        </div>
    );
}
